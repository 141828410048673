document.addEventListener("DOMContentLoaded", function () {
  const formElements = document.querySelectorAll(".search-form");

  formElements.forEach((form) => {
    form.addEventListener("focusin", function () {
      handleFocus(this);
    });

    form.addEventListener("focusout", function (event) {
      handleBlur(this, event);
    });
  });
});

function handleFocus(formElement) {
  formElement.classList.add("absolute", "w-full", "left-0", "z-40");
  const submitButton = formElement.querySelector(".fake-submit");
  if (submitButton) {
    submitButton.classList.remove("hidden");
  }
  const inputField = formElement.querySelector('input[type="search"]');
  if (inputField) {
    inputField.setAttribute(
      "placeholder",
      "Upišite željeni pojam i pretražite!"
    );
  }
}

function handleBlur(formElement, event) {
  const submitButton = formElement.querySelector(".fake-submit");
  const inputField = formElement.querySelector('input[type="search"]');
  if (
    !formElement.contains(event.relatedTarget) &&
    !submitButton.contains(event.relatedTarget)
  ) {
    formElement.classList.remove("absolute", "w-full", "left-0", "z-40");
    if (submitButton) {
      submitButton.classList.add("hidden");
    }
    if (inputField) {
      inputField.setAttribute("placeholder", "Pretražite ...");
    }
  }
}
function submitSearch(button) {
  const form = button.closest("form");
  if (form) {
    form.submit();
  }
}

$(document).ready(function () {
  $(".hero-slider").slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    dots: true,
    speed: 500,
    fade: true,
    cssEase: "linear",
  });
  $(".service-information").slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    arrows: false,
    dots: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });
});

document.addEventListener("DOMContentLoaded", function () {
  feather.replace();
});

document.addEventListener("DOMContentLoaded", function () {
  const headings = document.querySelectorAll(".item-heading");

  headings.forEach((heading, index) => {
    heading.addEventListener("click", () => {
      const body = heading.nextElementSibling;
      body.classList.toggle("active");
      if (body.style.display === "block") {
        body.style.display = "none";
      } else {
        body.style.display = "block";
      }
    });
  });
});

document.addEventListener("DOMContentLoaded", function () {
  const toggleHeaders = document.querySelectorAll("h2.open-items");

  toggleHeaders.forEach((header) => {
    header.addEventListener("click", function () {
      const ulElement = header.parentElement.querySelector("ul.all-items");
      if (
        ulElement.style.display === "none" ||
        ulElement.style.display === ""
      ) {
        ulElement.style.display = "block";
        ulElement.style.maxHeight = ulElement.scrollHeight + "px";
        ulElement.style.transition = "max-height 0.5s ease-out";
      } else {
        ulElement.style.maxHeight = "0";
        ulElement.style.overflow = "hidden";
        setTimeout(() => {
          ulElement.style.display = "none";
        }, 500);
      }
    });
  });
});

document.addEventListener("DOMContentLoaded", function () {
  const tabButtons = document.querySelectorAll(".tab-button");
  const tabContents = document.querySelectorAll(".tab-content");
  function resetTabs() {
    tabContents.forEach((content) => {
      content.classList.remove("is-active");
    });
    tabButtons.forEach((button) => {
      button.classList.remove("active");
    });
  }
  tabButtons.forEach((button) => {
    button.addEventListener("click", function () {
      const targetTab = button.getAttribute("data-tab");

      resetTabs();
      const targetContent = document.querySelector(
        `.tab-content[data-content="${targetTab}"]`
      );
      if (targetContent) {
        targetContent.classList.add("is-active");
      }
      button.classList.add("active");
    });
  });
});

const menuButton = document.getElementById("menu-button");
const closeButton = document.getElementById("close-button");
const menu = document.getElementById("menu");
const logo = document.getElementById("logo");

// Open menu
menuButton.addEventListener("click", () => {
  menu.classList.add("open");
  logo.classList.add("fixed");
});

// Close menu
closeButton.addEventListener("click", () => {
  menu.classList.remove("open");
  logo.classList.remove("fixed");
});
